<template>
    <div>
        <h2>Report Generator</h2>
        <b-container fluid>
            <b-row class="my-1">
                <b-col sm="4">
                 <b-card-group deck>
                    <b-card
                    header="Report Criteria"
                    header-tag="header">
                    <form ref="form" @submit.stop.prevent="generateReport">
                        <b-container fluid>
                             <b-row class="my-1">
                                <b-col sm="6">
                                    <center>
                                        <b-button variant="outline-primary" type="submit" stretch>
                                            Generate Report
                                        </b-button>
                                    </center>  
                                </b-col>  
                                <b-col sm="6">
                                    <center>
                                         <b-button variant="outline-primary" @click="SaveReport" stretch>
                                            Save Report
                                        </b-button>
                                    </center>
                                </b-col>   
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="4" >
                                    <label for="ID">Report No.</label>
                                </b-col>
                                <b-col sm="8" >
                                    <b-form-input id="ID" type="text" size="sm" v-model="search.reportId" disabled></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="4" >
                                    <label for="ReportName">Report Name</label>
                                </b-col>
                                <b-col sm="8" >
                                    <b-form-input 
                                    id="txtReportName"
                                    name="txtReportName" 
                                    v-model="$v.search.reportName.$model" 
                                    :state="validateState('reportName')"
                                    aria-describedby="reportName-feedback"
                                    type="text" size="sm"></b-form-input>
                                    <b-form-invalid-feedback id="reportName-feedback">
                                        This is a required field
                                    </b-form-invalid-feedback>
                                </b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="4" >
                                    <label for="Header">Header</label>
                                </b-col>
                                <b-col sm="8" >
                                    <b-form-input 
                                    id="txtHeader"
                                    name="txtHeader" 
                                    v-model="$v.search.reportHeader.$model" 
                                    :state="validateState('reportHeader')"
                                    aria-describedby="reportHeader-feedback"
                                    type="text" 
                                    size="sm" ></b-form-input>
                                    <b-form-invalid-feedback id="reportHeader-feedback">
                                        This is a required field
                                    </b-form-invalid-feedback>
                                </b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="4" >
                                    <label for="txtDetail">Detail</label>
                                </b-col>
                                <b-col sm="8" >
                                    <b-form-input 
                                    id="txtDetail" 
                                    name="txtDetail"
                                    type="text" 
                                    v-model="$v.search.reportDetails.$model" 
                                     :state="validateState('reportDetails')"
                                    aria-describedby="reportDetails-feedback"
                                    size="sm" ></b-form-input>
                                     <b-form-invalid-feedback id="reportDetails-feedback">
                                        This is a required field
                                    </b-form-invalid-feedback>
                                </b-col>
                            </b-row>
                            <b-row class="my-1">
                                    <b-col sm="1" >
                                        <label >Show</label>
                                    </b-col>
                                    <b-col sm="3" offset-md="1">
                                        <label>Field Name</label>
                                    </b-col>
                                    <b-col sm="4"  >
                                        <label>Choose Data</label>
                                    </b-col> 
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="1">
                                    <b-form-checkbox value="true" v-model="industryType" @change="updateReportColumn(industryType,'Industry Type')" />
                                </b-col>
                                <b-col sm="4" >
                                    <label>Industry Type</label>
                                </b-col>
                                <b-col sm="6" >
                                    <b-form-select id="ddlIndustryType"  
                                    name="ddlIndustryType"
                                    :options="allIndustryTypes" 
                                    v-model="$v.search.selectedIndustryType.$model" 
                                    :state="validateState('selectedIndustryType')"
                                    aria-describedby="selectedIndustryType-feedback" 
                                    text-field="englishName"
                                    value-field="id"
                                    :disabled="!industryType"></b-form-select>
                                     <b-form-invalid-feedback id="selectedIndustryType-feedback">
                                        This is a required field
                                    </b-form-invalid-feedback>
                                </b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="1">
                                    <b-form-checkbox value="true" v-model="businessSector" @change="updateReportColumn(businessSector,'Business Sector')" />
                                </b-col>
                                <b-col sm="4" >
                                    <label>Business Sector</label>
                                </b-col>
                                <b-col sm="7" >
                                    <b-form-select id="ddlBusinessSector" 
                                    multiple
                                    :options="allBusinessSectors" 
                                    text-field="englishName"
                                     v-model="$v.search.selectedBusinessSectors.$model" 
                                    :state="validateState('selectedBusinessSectors')"
                                    aria-describedby="selectedBusinessSectors-feedback" 
                                    value-field="id"
                                    :disabled="!businessSector"></b-form-select>
                                    <b-form-invalid-feedback id="selectedBusinessSectors-feedback">
                                        This is a required field
                                    </b-form-invalid-feedback>
                                </b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="1">
                                    <b-form-checkbox value="true" v-model="businessSubSector" @change="updateReportColumn(businessSubSector,'Business Sub-Sector')"/>
                                </b-col>
                                <b-col sm="4" >
                                    <label>Sub Sector</label>
                                </b-col>
                                <b-col sm="7" >
                                    <b-form-select id="ddlBusinessSubSector" 
                                    :options="allBusinessSubSectors" 
                                    multiple
                                    text-field="englishName"
                                    value-field="id"
                                     v-model="$v.search.selectedSubSectors.$model" 
                                    :state="validateState('selectedSubSectors')"
                                    aria-describedby="selectedSubSectors-feedback" 
                                    :disabled="!businessSubSector"></b-form-select>
                                     <b-form-invalid-feedback id="selectedSubSectors-feedback">
                                        This is a required field
                                    </b-form-invalid-feedback>
                                </b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="1">
                                    <b-form-checkbox value="true" v-model="legalStructure" @change="updateReportColumn(legalStructure,'Legal Structure')"/>
                                </b-col>
                                <b-col sm="4" >
                                    <label>Legal Structure</label>
                                </b-col>
                                <b-col sm="6" >
                                    <b-form-select id="ddlLegalStructure" 
                                    :options="allLegalStructure" 
                                    text-field="englishName"
                                    value-field="id"
                                     v-model="$v.search.selctedLegalStructure.$model" 
                                    :state="validateState('selctedLegalStructure')"
                                    aria-describedby="selctedLegalStructure-feedback" 
                                    :disabled="!legalStructure"></b-form-select>
                                     <b-form-invalid-feedback id="selctedLegalStructure-feedback">
                                        This is a required field
                                    </b-form-invalid-feedback>
                                </b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="1">
                                    <b-form-checkbox value="true"  v-model="jobFamily" @change="updateReportColumn(jobFamily,'Job Family')"/>
                                </b-col>
                                <b-col sm="4" >
                                    <label>Job Family</label>
                                </b-col>
                                <b-col sm="6" >
                                    <b-form-select id="ddlJobFamily" 
                                    :options="allJobFamilies"
                                    text-field="englishName"
                                    value-field="id" 
                                     v-model="$v.search.selectedJobFamily.$model" 
                                    :state="validateState('selectedJobFamily')"
                                    aria-describedby="selectedJobFamily-feedback" 
                                    :disabled="!jobFamily"></b-form-select>
                                    <b-form-invalid-feedback id="selectedJobFamily-feedback">
                                        This is a required field
                                    </b-form-invalid-feedback>
                                </b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="1">
                                    <b-form-checkbox value="true" v-model="position" @change="updateReportColumn(position,'Position')"/>
                                </b-col>
                                <b-col sm="4" >
                                    <label>Position</label>
                                </b-col>
                                 <b-col sm="7" >
                                    <b-form-select id="ddlPosition" 
                                    multiple
                                    :options="allPositions"
                                    text-field="englishDescription"
                                    value-field="id"  
                                     v-model="$v.search.selectedPositions.$model" 
                                    :state="validateState('selectedPositions')"
                                    aria-describedby="selectedPositions-feedback" 
                                    :disabled="!position"></b-form-select>
                                     <b-form-invalid-feedback id="selectedPositions-feedback">
                                        This is a required field
                                    </b-form-invalid-feedback>
                                </b-col>
                            </b-row>
                            <!-- <b-row class="my-1">
                                    <b-col sm="1">
                                        <b-form-checkbox value="true" v-model="jobImportance" @change="updateReportColumn(jobImportance,'Job Importance')"/>
                                    </b-col>
                                    <b-col sm="4" >
                                        <label>Job Importance</label>
                                    </b-col>
                                    <b-col sm="6" >
                                        <b-form-select id="ddljobImportance" v-model="selectedJobImportance" :options="options" :disabled="!jobImportance"></b-form-select>
                                    </b-col>
                            </b-row> -->
                            <b-row class="my-1">
                                <b-col sm="1">
                                    <b-form-checkbox value="true" v-model="countryOfInc" @change="updateReportColumn(countryOfInc,'Country Of Incorporation')"/>
                                </b-col>
                                <b-col sm="4" >
                                    <label>Country Of Incorporation</label>
                                </b-col>
                                <b-col sm="6" >
                                    <b-form-select id="ddlCountryOfIncorp" 
                                    text-field="countryName"
                                    value-field="id"  
                                    :options="allCountries" 
                                    v-model="$v.search.selectedCountryOfIncorp.$model" 
                                    :state="validateState('selectedCountryOfIncorp')"
                                    aria-describedby="selectedCountryOfIncorp-feedback" 
                                    :disabled="!countryOfInc"></b-form-select>
                                     <b-form-invalid-feedback id="selectedCountryOfIncorp-feedback">
                                        This is a required field
                                    </b-form-invalid-feedback>
                                </b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="1">
                                    <b-form-checkbox value="true" v-model="countryOfOp" @change="updateReportColumn(countryOfOp,'Country Of Operation')"/>
                                </b-col>
                                <b-col sm="4" >
                                    <label>Country Of Operation</label>
                                </b-col>
                                <b-col sm="6" >
                                    <b-form-select id="ddlCountryOfOperation" 
                                    :options="allCountries" 
                                    text-field="countryName"
                                    value-field="id"  
                                    v-model="$v.search.selectedCountryOfOperation.$model" 
                                    :state="validateState('selectedCountryOfOperation')"
                                    aria-describedby="selectedCountryOfOperation-feedback" 
                                    :disabled="!countryOfOp"></b-form-select>
                                     <b-form-invalid-feedback id="selectedCountryOfOperation-feedback">
                                        This is a required field
                                    </b-form-invalid-feedback>
                                </b-col>
                            </b-row>
                            <b-card-group deck>
                                    <b-card
                                    header="Medical Policy"
                                    header-tag="header">
                                        <b-row class="my-1">
                                            <b-col sm="1">
                                                <b-form-checkbox value="true" v-model="coverageScope" @change="updateReportColumn(coverageScope,'Coverage Scope')"/>
                                            </b-col>
                                            <b-col sm="4" >
                                                <label>Coverage Scope</label>
                                            </b-col>
                                            <b-col sm="6" >
                                                <b-form-select 
                                                id="ddlCoverageScope" 
                                                :options="medicalCoverageScopeOptions" 
                                                v-model="$v.search.selectedCoverageScope.$model" 
                                                :state="validateState('selectedCoverageScope')"
                                                aria-describedby="selectedCoverageScope-feedback" 
                                                :disabled="!coverageScope"></b-form-select>
                                                <b-form-invalid-feedback id="selectedCoverageScope-feedback">
                                                    This is a required field
                                                </b-form-invalid-feedback>
                                            </b-col>
                                        </b-row>
                                        <b-row class="my-1">
                                            <b-col sm="1">
                                                <b-form-checkbox value="true" v-model="serviceProvider" @change="updateReportColumn(serviceProvider,'Service Provider')"/>
                                            </b-col>
                                            <b-col sm="4" >
                                                <label>Service Provider</label>
                                            </b-col>
                                            <b-col sm="6" >
                                                <b-form-select 
                                                id="ddlServiceProvider" 
                                                :options="medicalServiceProviderOptions"
                                                 v-model="$v.search.selectedServiceProvider.$model" 
                                                :state="validateState('selectedServiceProvider')"
                                                aria-describedby="selectedServiceProvider-feedback"  
                                                :disabled="!serviceProvider"></b-form-select>
                                                <b-form-invalid-feedback id="selectedServiceProvider-feedback">
                                                    This is a required field
                                                </b-form-invalid-feedback>
                                            </b-col>
                                        </b-row>
                                        <b-row class="my-1">
                                            <b-col sm="1">
                                                <b-form-checkbox value="true" v-model="schemeType" @change="updateReportColumn(schemeType,'Scheme Type')"/>
                                            </b-col>
                                            <b-col sm="4" >
                                                <label>Scheme Type</label>
                                            </b-col>
                                            <b-col sm="6" >
                                                    <b-form-select 
                                                    id="ddlSchemeType" 
                                                    :options="medicalSchemeTypeOptions"
                                                    v-model="$v.search.selectedSchemeType.$model" 
                                                    :state="validateState('selectedSchemeType')"
                                                    aria-describedby="selectedSchemeType-feedback" 
                                                    :disabled="!schemeType"></b-form-select>
                                                     <b-form-invalid-feedback id="selectedSchemeType-feedback">
                                                        This is a required field
                                                    </b-form-invalid-feedback>
                                            </b-col>
                                        </b-row>
                                        <b-row class="my-1">
                                            <b-col sm="1">
                                                <b-form-checkbox value="true" v-model="coverageCategory" @change="updateReportColumn(coverageCategory,'Coverage Categories')"/>
                                            </b-col>
                                            <b-col sm="4" >
                                                <label>Coverage Categories</label>
                                            </b-col>
                                             <b-col sm="6" >
                                                <b-form-select id="ddlCoverageCategory" 
                                                multiple
                                                :options="allMediacalCoverageCategories" 
                                                v-model="$v.search.selectedCoverageCategories.$model" 
                                                :state="validateState('selectedCoverageCategories')"
                                                aria-describedby="selectedCoverageCategories-feedback" 
                                                :disabled="!coverageCategory"></b-form-select>
                                                 <b-form-invalid-feedback id="selectedCoverageCategories-feedback">
                                                        This is a required field
                                                    </b-form-invalid-feedback>
                                            </b-col>
                                        </b-row>
                                    </b-card>
                            </b-card-group>
                            <b-card-group deck>
                                <b-card
                                header="Life Insuance Policy"
                                header-tag="header">
                                    <b-row class="my-1">
                                        <b-col sm="1">
                                            <b-form-checkbox value="true" v-model="typeOfCoverage" @change="updateReportColumn(typeOfCoverage,'Life Insurance Type Of Coverage')"/>
                                        </b-col>
                                        <b-col sm="4" >
                                            <label>Type Of Coverage</label>
                                        </b-col>
                                        <b-col sm="6" >
                                            <b-form-select 
                                            id="ddlTypeOfCoverage" 
                                            :options="lifeInTypeOfCoverageOptions"
                                             v-model="$v.search.selctedTypeOfCoverage.$model" 
                                            :state="validateState('selctedTypeOfCoverage')"
                                            aria-describedby="selctedTypeOfCoverage-feedback" 
                                            :disabled="!typeOfCoverage"></b-form-select>
                                            <b-form-invalid-feedback id="selectedCoverageCategoryTo-feedback">
                                                This is a required field
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-card>
                            </b-card-group>
                            <b-card-group deck>
                                <b-card
                                header="Physical Disability Policy"
                                header-tag="header">
                                    <b-row class="my-1">
                                        <b-col sm="1">
                                            <b-form-checkbox value="true" v-model="physicalDisTypeOfCoverage" @change="updateReportColumn(physicalDisTypeOfCoverage,'Physical Disability Type Of Coverage')"/>
                                        </b-col>
                                        <b-col sm="4" >
                                            <label>Type Of Coverage</label>
                                        </b-col>
                                        <b-col sm="6" >
                                            <b-form-select 
                                            id="ddlTypeOfCoverage" 
                                            :options="physicalDisTypeOfCoverageOptions" 
                                            v-model="$v.search.selectedPhysicalDisTypeOfCoverage.$model" 
                                            :state="validateState('selectedPhysicalDisTypeOfCoverage')"
                                            aria-describedby="selectedPhysicalDisTypeOfCoverage-feedback" 
                                            :disabled="!physicalDisTypeOfCoverage"></b-form-select>
                                            <b-form-invalid-feedback id="selectedPhysicalDisTypeOfCoverage-feedback">
                                                This is a required field
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-card>
                            </b-card-group>
                            <b-card-group deck>
                                <b-card
                                header="Overtime Policy"
                                header-tag="header">
                                    <b-row class="my-1">
                                        <b-col sm="1">
                                            <b-form-checkbox value="true" v-model="overTimeTypeOfCoverage" @change="updateReportColumn(overTimeTypeOfCoverage,'Over-Time Type Of Coverage')"/>
                                        </b-col>
                                        <b-col sm="4" >
                                            <label>Type Of Coverage</label>
                                        </b-col>
                                        <b-col sm="6" >
                                            <b-form-select 
                                            id="ddlTypeOfCoverage" 
                                            :options="overtimeExistsOptions" 
                                             v-model="$v.search.selectedOvertimeTypeOfCoverage.$model" 
                                            :state="validateState('selectedOvertimeTypeOfCoverage')"
                                            aria-describedby="selectedOvertimeTypeOfCoverage-feedback" 
                                            :disabled="!overTimeTypeOfCoverage"></b-form-select>
                                            <b-form-invalid-feedback id="selectedOvertimeTypeOfCoverage-feedback">
                                                This is a required field
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-card>
                            </b-card-group>
                            <b-card-group deck>
                                <b-card
                                header="Sales Commission Policy"
                                header-tag="header">
                                    <b-row class="my-1">
                                        <b-col sm="1">
                                            <b-form-checkbox value="true" v-model="commissionBasis" @change="updateReportColumn(commissionBasis,'Sales Commission Basis')"/>
                                        </b-col>
                                        <b-col sm="4" >
                                            <label>Basis</label>
                                        </b-col>
                                        <b-col sm="6" >
                                            <b-form-select 
                                            id="ddlTypeOfCoverage" 
                                            :options="CommissionBasisOptions" 
                                             v-model="$v.search.selectedCommissionBasis.$model" 
                                            :state="validateState('selectedCommissionBasis')"
                                            aria-describedby="selectedCommissionBasis-feedback" 
                                            :disabled="!commissionBasis"></b-form-select>
                                            <b-form-invalid-feedback id="selectedCommissionBasis-feedback">
                                                This is a required field
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                    <b-row class="my-1">
                                        <b-col sm="1">
                                            <b-form-checkbox value="true" v-model="CommissionSchemeModel" @change="updateReportColumn(CommissionSchemeModel,'Sales Commission Scheme Model')"/>
                                        </b-col>
                                        <b-col sm="4" >
                                            <label>Scheme Model</label>
                                        </b-col>
                                        <b-col sm="6" >
                                            <b-form-select 
                                            id="ddlSchemeModel"
                                            :options="schemeModelOptions" 
                                             v-model="$v.search.selectedCommissionSchemeModel.$model" 
                                            :state="validateState('selectedCommissionSchemeModel')"
                                            aria-describedby="selectedCommissionSchemeModel-feedback" 
                                            :disabled="!CommissionSchemeModel"></b-form-select>
                                            <b-form-invalid-feedback id="selectedCommissionSchemeModel-feedback">
                                                This is a required field
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                    <b-row class="my-1">
                                        <b-col sm="1">
                                            <b-form-checkbox value="true" v-model="commissionModel" @change="updateReportColumn(commissionModel,'Sales Commission Model')"/>
                                        </b-col>
                                        <b-col sm="4" >
                                            <label>Model</label>
                                        </b-col>
                                        <b-col sm="6" >
                                            <b-form-select 
                                            id="ddlcommissionModel" 
                                            :options="commissionModelOptions" 
                                             v-model="$v.search.selectedCommissionModel.$model" 
                                            :state="validateState('selectedCommissionModel')"
                                            aria-describedby="selectedCommissionModel-feedback" 
                                            :disabled="!commissionModel"></b-form-select>
                                            <b-form-invalid-feedback id="selectedCommissionModel-feedback">
                                                This is a required field
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                    <b-row class="my-1">
                                        <b-col sm="1">
                                            <b-form-checkbox value="true" v-model="commissionFreqOfPayment" @change="updateReportColumn(commissionFreqOfPayment,'Sales Commission Frequency Of Payment')"/>
                                        </b-col>
                                        <b-col sm="4" >
                                            <label>Frequencey of Payment</label>
                                        </b-col>
                                        <b-col sm="6" >
                                            <b-form-select 
                                            id="ddlFreqOfPayment" 
                                            :options="freqOfPaymentOptions" 
                                             v-model="$v.search.selectedFreqOfPayment.$model" 
                                            :state="validateState('selectedFreqOfPayment')"
                                            aria-describedby="selectedFreqOfPayment-feedback" 
                                            :disabled="!commissionFreqOfPayment"></b-form-select>
                                             <b-form-invalid-feedback id="selectedFreqOfPayment-feedback">
                                                This is a required field
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-card>
                            </b-card-group>
                            <b-card-group deck>
                                <b-card
                                header="Pension Policy"
                                header-tag="header">
                                    <b-row class="my-1">
                                        <b-col sm="1">
                                            <b-form-checkbox value="true" v-model="PermissionPolicyScheme" @change="updateReportColumn(PermissionPolicyScheme,'Permission Policy')"/>
                                        </b-col>
                                        <b-col sm="4" >
                                            <label>Scheme</label>
                                        </b-col>
                                        <b-col sm="6" >
                                            <b-form-select 
                                            id="ddlPermissionPolicyScheme" 
                                            :options="pensionSchemeDetailsOptions"
                                             v-model="$v.search.selectedPermissionPolicyScheme.$model" 
                                            :state="validateState('selectedPermissionPolicyScheme')"
                                            aria-describedby="selectedPermissionPolicyScheme-feedback" 
                                            :disabled="!PermissionPolicyScheme"></b-form-select>
                                            <b-form-invalid-feedback id="selectedPermissionPolicyScheme-feedback">
                                                This is a required field
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-card>
                            </b-card-group>
                            <b-row class="my-1">
                                 <b-col sm="12">
                                     <center>
                                         <b-button variant="outline-primary" type="submit" stretch>
                                            Generate Report
                                        </b-button>
                                     </center>
                                 </b-col>
                            </b-row>
                        </b-container>
                    </form>
                    </b-card>
                </b-card-group>
                </b-col>
                <b-col sm="8">
                     <b-table 
                        ref ="ReportsListing"
                        id="ReportsListingTable"
                         :per-page="perPage"
                        :current-page="currentPage"
                        :items="reportsList" 
                        :fields="reportsListingFields"
                        
                        responsive="sm">
                         <template #cell(Actions)="row">
                              <b-button-toolbar>
                                   <b-button-group class="mr-1">
                                       <b-button  squared variant="primary" @click="showReport(row.item,row.index)">
                                            Show
                                        </b-button>
                                        <b-button  variant="primary" @click="editReport(row.item,row.index,1)">
                                            Edit
                                        </b-button>
                                   </b-button-group>   
                              </b-button-toolbar>
                         </template>
                     </b-table>
                            <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                            aria-controls="ReportsListingTable">
                            </b-pagination>
                        <b-container fluid class="mt-6" style="margin-top:100px">
                            <b-row class="bg-info">
                                <b-col  class="text-center " >
                                    <h3>Report</h3>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col  class="text-center " >
                                    <h3>{{ReportHeader}}</h3>
                                </b-col>
                            </b-row>
                             <b-row>
                                <b-col  class="text-center " >
                                    <h5>{{ReportDetails}}</h5>
                                </b-col>
                            </b-row>
                        </b-container>
                         <b-table  
                            striped hover 
                            responsive
                            :items="reportGeneratorResult" 
                            :fields="reportGeneratorFields"
                            ref="reportResult"
                            v-if="reportGeneratorResult !=null">
                         </b-table>  
                         <div v-if="reportGeneratorResult == null">
                            <center>
                                <h2>No Items to display</h2>
                            </center>
                        </div>
                        <!-- <div class="box">
                            <vue-scrolling-table scroll-horizontal="true" sync-header-scroll="true" >
                             <template slot="thead">
                                  <tr>
                                    <th v-for="(col,index) in ReportColumns" :key="index"><div label-class="font-weight-bold pt-0" >{{col}}</div></th>  
                                  </tr>
                             </template>
                             <template slot="tbody">
                                  <tr>
                                    <td v-for="(col,index) in ReportColumns" :key="index"></td>  
                                  </tr>
                             </template>
                         </vue-scrolling-table>
                        </div>  -->
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
// import VueScrollingTable from "vue-scrolling-table"
import { validationMixin } from "vuelidate";
import { required,requiredIf } from "vuelidate/lib/validators";
export default {
    mixins: [validationMixin],
    // components: {
    //     VueScrollingTable,
    // },
    computed: {
        customerNumber () {
            return this.$store.state.userDetails.subscriptionId;
        },
        surveyYear(){
            return this.$store.getters.surveyYear;
        },
        rows() {
        return this.reportsList.length
      }
    },
    data(){
        return{
            //pagination fields:
             currentPage: 1,
             perPage: 2,
             //-------------
            industryType:false,
            businessSector:false,
            businessSubSector:false,
            legalStructure:false,
            jobFamily:false,
            position:false,
            jobImportance:false,
            countryOfInc:false,
            countryOfOp:false,
            coverageScope:false,
            serviceProvider:false,
            schemeType:false,
            coverageCategory:false,
            typeOfCoverage:false,
            physicalDisTypeOfCoverage:false,
            overTimeTypeOfCoverage:false,
            commissionBasis:false,
            CommissionSchemeModel:false,
            commissionModel:false,
            PermissionPolicyScheme:false,
            commissionFreqOfPayment:false,
            
            search:{
                reportId:null,
                reportName:null,
                reportHeader:null,
                reportDetails:null,
                selectedIndustryType:null,
                selectedBusinessSectors:[],
                selectedSubSectors:[],
                selctedLegalStructure:null,
                selectedJobFamily:null,
                selectedPositions:[],
                selectedJobImportance:null,
                selectedCountryOfIncorp:null,
                selectedCountryOfOperation:null,
                selectedCoverageScope:null,
                selectedServiceProvider:null,
                selectedSchemeType:null,
                selectedCoverageCategories:[],
                selctedTypeOfCoverage:null,
                selectedPhysicalDisTypeOfCoverage:null,
                selectedOvertimeTypeOfCoverage:null,
                selectedCommissionBasis:null,
                selectedCommissionSchemeModel:null,
                selectedCommissionModel:null,
                selectedFreqOfPayment:null,
                selectedPermissionPolicyScheme:null,
            },
            ReportHeader:null,
            ReportDetails:null,
            ReportColumns:[],
            reportsList:[],
            reportsListingFields:['id','reportName','reportHeader','reportDetails','Actions'],
            allIndustryTypes:[],
            allBusinessSectors:[],
            allBusinessSubSectors:[],
            allLegalStructure:[],
            allJobFamilies:[],
            allPositions:[],
            allCountries:[],
            medicalCoverageScopeOptions:[{ value: 1, text:'Individual'},{ value: 2, text:'Family'}],
            medicalServiceProviderOptions:[{ value: 1, text:'Insurance Company'} ,{ value: 2, text:'Third Party Admin'} ],
            medicalSchemeTypeOptions:[{ value: 1, text:'Captive Insurance'} ,{ value: 2, text:'Self Admin Insurance'}],
            allMediacalCoverageCategories:[],
            lifeInTypeOfCoverageOptions:[{ value: 1, text:'Normal Death'} ,
                                        { value: 2, text:'Accidental Death'} ,
                                        { value: 3, text:'Both'} ],
             physicalDisTypeOfCoverageOptions:[{ value: 1, text:'Permenant'} , { value: 2, text:'Partial'} ],
            overtimeExistsOptions:["Yes","No"],
            CommissionBasisOptions:[
                { value: 1, text: 'Salary Based' },
                { value: 2, text: 'Volume Based' },
                { value: 3, text: 'Revenue Based' }],
             commissionModelOptions:[
                 { value: 1, text: 'Fixed Amount Per Sale' },
                 { value: 2, text: 'Calculation Basis Percent' }],
             freqOfPaymentOptions:[
                 { value: 1, text: 'Monthly' },
                 { value: 2, text: 'Quarterly' },
                 { value: 3, text: 'Semi-Annual' },
                 { value: 4, text: 'Annual' },
                 { value: 5, text: 'Other' }],
             schemeModelOptions:[
                 { value: 1, text: 'Progressive' },
                 { value: 2, text: 'Regressive' },
                 { value: 3, text: 'Flat' }],
             pensionSchemeDetailsOptions:[
                 { value: 1, text: '100/0' },
                 { value: 2, text: '80/20' },
                 { value: 3, text: '60/40' },
                 { value: 4, text: '50/50' }],
            reportGeneratorResult:null,
            reportGeneratorFields:[
                'customerName',
                'industryType',
                'businessSector',
                'businessSubSector',
                'legalStructure',
                'jobFamily',
                'position',
                'countryOfCorporation',
                'countryOfOperation',
                'medicalCoverageScope',
                'medicalServiceProvider',
                'medicalSchemeType',
                'medicalCoverageCategories',
                'lifeInsuranceTypeOfCoverage',
                'physicalDisabilityTypeOfCoverage',
                'hasOvertimePolicy',
                'commissionBasis',
                'commissionSchemeModel',
                'commissionModel',
                'commissionFreqOfPaymentId',
                'pansionScheme'
            ],
            currentEditedReport:null,
        }
    },
    validations:{
        search:{
            reportName:{
                required
            },
            reportHeader:{
                required
            },
            reportDetails:{
                required
            },
             selectedIndustryType:{
                  required:requiredIf(function(){
                    return Boolean(this.industryType) == true;
                })
              },
              selectedBusinessSectors:{
                  required:requiredIf(function(){
                    return this.businessSector == 'true';
                })
              },
            //   selectedBusinessSectorTo:{
            //       required:requiredIf(function(){
            //         return this.businessSector == 'true';
            //     })
            //   },
              selectedSubSectors:{
                  required:requiredIf(function(){
                    return this.businessSubSector == 'true';
                })
              },
            //   selectedSubSectorTo:{
            //       required:requiredIf(function(){
            //         return this.businessSubSector == 'true';
            //     })
            //   },
              selctedLegalStructure:{
                  required:requiredIf(function(){
                    return this.legalStructure == 'true';
                })
              },
               selectedJobFamily:{
                  required:requiredIf(function(){
                    return this.jobFamily == 'true';
                })
              },
              selectedPositions:{
                  required:requiredIf(function(){
                    return this.position == 'true';
                })
              },
            //   selectedPositionTo:{
            //       required:requiredIf(function(){
            //         return this.position == 'true';
            //     })
            //   },
              selectedCountryOfIncorp:{
                  required:requiredIf(function(){
                    return this.countryOfInc == 'true';
                })
              },
              selectedCountryOfOperation:{
                  required:requiredIf(function(){
                    return this.countryOfOp == 'true';
                })
              },
              selectedCoverageScope:{
                  required:requiredIf(function(){
                    return this.coverageScope == 'true';
                })
              },
              selectedServiceProvider:{
                  required:requiredIf(function(){
                    return this.serviceProvider == 'true';
                })
              },
              selectedSchemeType:{
                  required:requiredIf(function(){
                    return this.schemeType == 'true';
                })
              },
              selectedCoverageCategories:{
                  required:requiredIf(function(){
                    return this.coverageCategory == 'true';
                })
              },
            //   selectedCoverageCategoryTo:{
            //       required:requiredIf(function(){
            //         return this.coverageCategory == 'true';
            //     })
            //   },
               selctedTypeOfCoverage:{
                  required:requiredIf(function(){
                    return this.typeOfCoverage == 'true';
                })
              },
              selectedPhysicalDisTypeOfCoverage:{
                  required:requiredIf(function(){
                    return this.physicalDisTypeOfCoverage == 'true';
                })
              },
              selectedOvertimeTypeOfCoverage:{
                  required:requiredIf(function(){
                    return this.overTimeTypeOfCoverage == 'true';
                })
              },
               selectedCommissionBasis:{
                  required:requiredIf(function(){
                    return this.commissionBasis == 'true';
                })
              },
              selectedCommissionSchemeModel:{
                  required:requiredIf(function(){
                    return this.CommissionSchemeModel == 'true';
                })
              },
              selectedCommissionModel:{
                  required:requiredIf(function(){
                    return this.commissionModel == 'true';
                })
              },
              selectedFreqOfPayment:{
                  required:requiredIf(function(){
                    return this.PermissionPolicyScheme == 'true';
                })
              },
              selectedPermissionPolicyScheme:{
                  required:requiredIf(function(){
                    return this.commissionFreqOfPayment == 'true';
                })
              },
        }
    },
     methods:{
         loadReports(){
                this.$axios({
                    method: 'get',
                    url: `${this.$baseURL}/Report?customerNumber=${this.customerNumber}`,
                })
                .then((response)=>{
                    if(JSON.parse(response.data.status) == true){
                        this.reportsList = response.data.reports;
                    }
                    
                });
         },
         loadIndustryTypes(){
             this.$axios({
                    method: 'get',
                    url: `${this.$baseURL}/IndustryType`,
                })
                .then((response)=>{
                    this.allIndustryTypes = response.data;
                });
         },
         loadBusinessSectors(){
             this.$axios({
                    method: 'get',
                    url: `${this.$baseURL}/BusinessSector`,
                })
                .then((response)=>{
                    this.allBusinessSectors = response.data.map(b=>({id:b.id,englishName:b.id + "-" +b.englishName}) );
                });
         },
         loadBusinessSubSectors(){
             this.$axios({
                    method: 'get',
                    url: `${this.$baseURL}/BusinessSubSector`,
                })
                .then((response)=>{
                    this.allBusinessSubSectors = response.data.map(b=>({id:b.id,englishName:b.id + "-" +b.englishName}) );
                });
         },
         loadLegalStructure(){
             this.$axios({
                    method: 'get',
                    url: `${this.$baseURL}/LegalStructure`,
                })
                .then((response)=>{
                    this.allLegalStructure = response.data;
                });
         },
         loadJobFamilies(){
             this.$axios({
                    method: 'get',
                    url: `${this.$baseURL}/JobFamily`,
                })
                .then((response)=>{
                    this.allJobFamilies = response.data;
                });
         },
         loadPositions(){
             this.$axios({
                    method: 'get',
                    url: `${this.$baseURL}/JobTitle`,
                })
                .then((response)=>{
                    this.allPositions = response.data.map(b=>({id:b.id,englishDescription:b.id + "-" +b.englishDescription}) );
                });
         },
         loadCountries(){
             this.$axios({
                    method: 'get',
                    url: `${this.$baseURL}/CustomerProfile/countrires`,
                })
                .then((response)=>{
                    this.allCountries = response.data;
                });
         },
         loadMedicalCoverageCategories(){
              this.$axios({
                    method: 'get',
                    url: `${this.$baseURL}/CustomerProfile/countrires`,
                })
                .then((response)=>{
                    if(JSON.parse(response.data.status) == true)
                    {
                        this.allMediacalCoverageCategories = response.data.medicalCategories;
                    }
                });
         },
         updateReportColumn(item,columnName){
             switch(columnName)
             {
                case 'Industry Type':
                     if(item){
                         if(this.allIndustryTypes.length == 0){ 
                             this.loadIndustryTypes();
                         }
                     }
                     else{
                         this.search.selectedIndustryType = null
                     }
                 break;
                case 'Business Sector':
                     if(item){
                          if(this.allBusinessSectors.length == 0){ 
                             this.loadBusinessSectors();
                         }
                     }
                     else{
                         this.search.selectedBusinessSectors = []
                     }
                     break;
                case 'Business Sub-Sector':
                    if(item){
                          if(this.allBusinessSubSectors.length == 0){ 
                             this.loadBusinessSubSectors();
                         }
                     }
                    else{
                         this.search.selectedSubSectors = []
                     }
                    break;
                case 'Legal Structure':
                    if(item){
                          if(this.allLegalStructure.length == 0){ 
                             this.loadLegalStructure();
                         }
                     }
                     else{
                         this.search.selctedLegalStructure = null
                     }
                    break;
                case 'Job Family':
                    if(item){
                          if(this.allJobFamilies.length == 0){ 
                             this.loadJobFamilies();
                         }
                     }
                     else{
                         this.search.selectedJobFamily = null
                     }
                    break;
                case 'Position':
                     if(item){
                          if(this.allPositions.length == 0){ 
                             this.loadPositions();
                         }
                     }
                     else{
                         this.search.selectedPositions = []
                     }
                    break;
                case 'Country Of Incorporation':
                    if(item){
                          if(this.allCountries.length == 0){ 
                             this.loadCountries();
                         }
                     }
                     else{
                         this.search.selectedCountryOfIncorp = null
                     }
                    break;
                case 'Country Of Operation':
                    if(item){
                          if(this.allCountries.length == 0){ 
                             this.loadCountries();
                         }
                     }
                     else{
                         this.search.selectedCountryOfOperation = null
                     }
                    break
                case 'Service Provider':
                    if(!item){
                         this.search.selectedServiceProvider = null
                    }
                    break
                case 'Scheme Type':
                    if(!item){
                         this.search.selectedSchemeType = null
                    }
                    break
                case 'Coverage Categories':
                    if(item){
                          if(this.allMediacalCoverageCategories.length == 0){ 
                             this.loadMedicalCoverageCategories();
                         }
                     }
                     else{
                         this.search.selectedCoverageCategories = []
                     }
                    break;
                case 'Life Insurance Type Of Coverage':
                    if(!item){
                         this.search.selctedTypeOfCoverage = null
                    }
                    break
                case 'Physical Disability Type Of Coverage':
                    if(!item){
                         this.search.selectedPhysicalDisTypeOfCoverage = null
                    }
                    break
                case 'Over-Time Type Of Coverage':
                    if(!item){
                         this.search.selectedOvertimeTypeOfCoverage = null
                    }
                    break
                case 'Sales Commission Basis':
                    if(!item){
                         this.search.selectedCommissionBasis = null
                    }
                    break
                case 'Sales Commission Scheme Model':
                    if(!item){
                         this.search.selectedCommissionSchemeModel = null
                    }
                    break
                case 'Sales Commission Model':
                    if(!item){
                         this.search.selectedCommissionModel = null
                    }
                    break
                case 'Sales Commission Frequency Of Payment':
                    if(!item){
                         this.search.selectedFreqOfPayment = null
                    }
                    break
                case 'Permission Policy':
                    if(!item){
                         this.search.selectedPermissionPolicyScheme = null
                    }
                    break
                
             }
            //  if(item){
            //         this.ReportColumns.push(columnName)
            //  }
            //  else{
            //      const index = this.ReportColumns.indexOf(columnName, 0);
            //      if (index > -1) {
            //         this.ReportColumns.splice(index, 1);
            //     }
            //  }
         },
          validateState(name) {
            const { $dirty, $error } = this.$v.search[name];
            return $dirty ? !$error : null;
        },
        SaveReport(){
            this.$v.search.$touch();
            if (this.$v.search.$anyError) {
                return;
            }
            this.$axios({
                    method: 'post',
                    url: `${this.$baseURL}/Report`,
                    headers: {'Content-Type': 'application/json' },
                    data:{
                        Id:this.search.reportId??-1,
                        SurveyYear:this.surveyYear,
                        CustomerNumber:this.customerNumber,
                        ReportName:this.search.reportName,
                        ReportHeader:this.search.reportHeader,
                        ReportDetails:this.search.reportDetails,
                        IndustryType:this.search.selectedIndustryType??-1,
                        BusinessSectors:this.search.selectedBusinessSectors.length == 0 ? "" : this.search.selectedBusinessSectors.toString(),
                        BusinessSubSectors:this.search.selectedSubSectors.length == 0 ?'':this.search.selectedSubSectors.toString(),
                        LegalStructure:this.search.selctedLegalStructure??-1,
                        JobFamily:this.search.selectedJobFamily??'',
                        Positions:this.search.selectedPositions.length == 0? '':this.search.selectedPositions.toString(),
                        CountryOfInc:this.search.selectedCountryOfIncorp??-1,
                        CountryOfOp:this.search.selectedCountryOfOperation??-1,
                        MedicalCoverageScope:this.search.selectedCoverageScope??-1,
                        MedicalCoverageServiceProvider:this.search.selectedServiceProvider??-1,
                        MedicalCoverageSchemeType:this.search.selectedSchemeType??-1,
                        CoverageCategories:this.search.selectedCoverageCategories.length ==0?'' :this.search.selectedCoverageCategories.toString(),
                        LifeInsuraceTypeOfCoverage:this.search.selctedTypeOfCoverage??-1,
                        PhysicalDisTypeOfCoverage:this.search.selectedPhysicalDisTypeOfCoverage??-1,
                        OverTimePolicy:this.search.selectedOvertimeTypeOfCoverage == null?-1:this.search.selectedOvertimeTypeOfCoverage == 'Yes'?1:2,
                        SalesCommissionPolicyBasis:this.search.selectedCommissionBasis??-1,
                        SalesCommissionPolicySchemeModel:this.search.selectedCommissionSchemeModel??-1,
                        SalesCommissionPolicyModel:this.search.selectedCommissionModel??-1,
                        SalesCommissionPolicyFreqOfPayment:this.search.selectedFreqOfPayment??-1,
                        PensionPolicy:this.search.selectedPermissionPolicyScheme??-1,

                    }
                }).then((response)=>{
                    if(JSON.parse(response.data.status) == true){
                        this.$bvModal.msgBoxOk('Report Added Successfully', {
                                            title: 'Confirmation',
                                            size: 'sm',
                                            buttonSize: 'sm',
                                            okVariant: 'success',
                                            headerClass: 'p-2 border-bottom-0',
                                            footerClass: 'p-2 border-top-0',
                                            centered: true
                                            });
                        this.reportsList = response.data.reports;
                        this.$refs.ReportsListing.refresh();
                        this.reset();
                    }
                    
                    

                });

        },
        reset(){
            this.search.reportId = null;
            this.search.reportName = null;
            this.search.reportHeader = null;
            this.search.reportDetails = null;
            this.industryType = false;
            this.businessSector = false;
            this.businessSubSector = false;
            this.legalStructure = false;
            this.jobFamily = false;
            this.position = false;
            this.countryOfInc = false;
            this.countryOfOp = false;
            this.coverageScope = false;
            this.serviceProvider = false;
            this.schemeType = false;
            this.coverageCategory = false;
            this.typeOfCoverage = false;
            this.physicalDisTypeOfCoverage = false;
            this.overTimeTypeOfCoverage = false;
            this.commissionBasis = false;
            this.CommissionSchemeModel = false;
            this.commissionModel = false;
            this.PermissionPolicyScheme = false;
            this.commissionFreqOfPayment = false;
             this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        editReport(item,index){
            this.currentEditedReport  = index;
             this.$axios({
                    method: 'get',
                    url: `${this.$baseURL}/Report/Report?reportId=${item.id}`,
                    headers: {'Content-Type': 'application/json' },
                }).then((response)=>{
                    console.log( response);
                    // console.log(JSON.parse(response.data.reportDetails.businessSubSectors));
                    if(JSON.parse(response.data.status) == true){
                        this.search.reportId = item.id;
                        this.search.reportName = response.data.reportDetails.reportName;
                        this.search.reportHeader = response.data.reportDetails.reportHeader;
                        this.search.reportDetails = response.data.reportDetails.reportDetails;

                        if(response.data.reportDetails.industryType == -1){
                            this.industryType = false;     
                        } 
                        else{
                             this.loadIndustryTypes();
                             this.industryType = true;
                             this.search.selectedIndustryType = JSON.parse(response.data.reportDetails.industryType);
                        } 
                        if(response.data.reportDetails.businessSectors == null || response.data.reportDetails.businessSectors =='' ){
                            this.businessSector = false;     
                        } 
                        else{
                            this.loadBusinessSectors();
                             this.businessSector = true;
                             this.search.selectedBusinessSectors =String(JSON.parse(JSON.stringify(response.data.reportDetails.businessSectors))).split(',');
                        } 
                        if(response.data.reportDetails.businessSubSectors == null || response.data.reportDetails.businessSubSectors=='' ){
                            this.businessSubSector = false;     
                        } 
                        else{
                            this.loadBusinessSubSectors();
                             this.businessSubSector = true;
                             this.search.selectedSubSectors = String(JSON.parse(JSON.stringify(response.data.reportDetails.businessSubSectors))).split(',');
                        } 
                        // if(response.data.reportDetails.legalStructure == null || response.data.reportDetails.legalStructure == -1){
                        //     this.legalStructure = false;     
                        // } 
                        // else{
                        //     this.loadLegalStructure()
                        //      this.legalStructure = true;
                        //      this.search.selctedLegalStructure = JSON.parse(response.data.reportDetails.legalStructure);
                        // } 
                        // if(response.data.reportDetails.jobFamily == null || response.data.reportDetails.jobFamily == ''){
                        //     this.jobFamily = false;     
                        // } 
                        // else{
                        //     this.loadJobFamilies();
                        //      this.jobFamily = true;
                        //      this.search.selectedJobFamily = JSON.parse(response.data.reportDetails.jobFamily);
                        // } 
                        // if(response.data.reportDetails.positions == null || response.data.reportDetails.positions=='' ){
                        //     this.position = false;     
                        // } 
                        // else{
                        //      this.loadPositions();
                        //      this.position = true;
                        //      this.search.selectedPositions = String(JSON.parse(response.data.reportDetails.selectedPositions)).split(',');
                        // } 
                        // if(response.data.reportDetails.countryOfInc == null || response.data.reportDetails.countryOfInc == -1){
                        //     this.countryOfInc = false;     
                        // } 
                        // else{
                        //     this.loadCountries();
                        //      this.countryOfInc = true;
                        //      this.search.selectedCountryOfIncorp = JSON.parse(response.data.reportDetails.countryOfInc);
                        // } 
                        //  if(response.data.reportDetails.countryOfOp == null || response.data.reportDetails.countryOfOp == -1){
                        //     this.countryOfOp = false;     
                        // } 
                        // else{
                        //     this.loadCountries();
                        //      this.countryOfOp = true;
                        //      this.search.selectedCountryOfOperation = JSON.parse(response.data.reportDetails.countryOfOp);
                        // } 
                        //  if(response.data.reportDetails.medicalCoverageScope == null || response.data.reportDetails.medicalCoverageScope == -1){
                        //     this.coverageScope = false;     
                        // } 
                        // else{
                        //      this.coverageScope = true;
                        //      this.search.selectedCoverageScope = JSON.parse(response.data.reportDetails.medicalCoverageScope);
                        // } 
                        //  if(response.data.reportDetails.medicalCoverageServiceProvider == null  || response.data.reportDetails.medicalCoverageServiceProvider == -1){
                        //     this.serviceProvider = false;     
                        // } 
                        // else{
                        //      this.serviceProvider = true;
                        //      this.search.selectedServiceProvider = JSON.parse(response.data.reportDetails.medicalCoverageServiceProvider);
                        // } 
                        //  if(response.data.reportDetails.medicalCoverageSchemeType == null || response.data.reportDetails.medicalCoverageSchemeType == -1){
                        //     this.schemeType = false;     
                        // } 
                        // else{
                        //      this.schemeType = true;
                        //      this.search.medicalCoverageSchemeType = JSON.parse(response.data.reportDetails.medicalCoverageSchemeType);
                        // } 
                        //  if(response.data.reportDetails.coverageCategories == null || response.data.reportDetails.coverageCategories=='' ){
                        //     this.coverageCategory = false;     
                        // } 
                        // else{
                        //      this.coverageCategory = true;
                        //      this.search.selectedCoverageCategories = String(JSON.parse(response.data.reportDetails.coverageCategories)).split(',');
                        // } 
                        //  if(response.data.reportDetails.lifeInsuraceTypeOfCoverage == null || response.data.reportDetails.lifeInsuraceTypeOfCoverage  == -1){
                        //     this.typeOfCoverage = false;     
                        // } 
                        // else{
                        //      this.typeOfCoverage = true;
                        //      this.search.selctedTypeOfCoverage = JSON.parse(response.data.reportDetails.lifeInsuraceTypeOfCoverage);
                        // } 
                        // if(response.data.reportDetails.physicalDisTypeOfCoverage == null || response.data.reportDetails.physicalDisTypeOfCoverage == -1){
                        //     this.physicalDisTypeOfCoverage = false;     
                        // } 
                        // else{
                        //      this.physicalDisTypeOfCoverage = true;
                        //      this.search.selectedPhysicalDisTypeOfCoverage = JSON.parse(response.data.reportDetails.physicalDisTypeOfCoverage);
                        // } 
                        // if(response.data.reportDetails.overTimePolicy == null || response.data.reportDetails.overTimePolicy == -1){
                        //     this.overTimeTypeOfCoverage = false;     
                        // } 
                        // else{
                        //      this.overTimeTypeOfCoverage = true;
                        //      this.search.selectedOvertimeTypeOfCoverage = JSON.parse(response.data.reportDetails.overTimePolicy);
                        // } 
                        // if(response.data.reportDetails.salesCommissionPolicyBasis == null || response.data.reportDetails.salesCommissionPolicyBasis == -1){
                        //     this.commissionBasis = false;     
                        // } 
                        // else{
                        //      this.commissionBasis = true;
                        //      this.search.selectedCommissionBasis =JSON.parse(response.data.reportDetails.salesCommissionPolicyBasis);
                        // } 
                        //  if(response.data.reportDetails.salesCommissionPolicySchemeModel == null || response.data.reportDetails.salesCommissionPolicySchemeModel == -1){
                        //     this.CommissionSchemeModel = false;     
                        // } 
                        // else{
                        //      this.CommissionSchemeModel = true;
                        //      this.search.selectedCommissionSchemeModel = JSON.parse(response.data.reportDetails.salesCommissionPolicySchemeModel);
                        // } 
                          
                        //  if(response.data.reportDetails.salesCommissionPolicyModel == null || response.data.reportDetails.salesCommissionPolicyModel  == -1){
                        //     this.commissionModel = false;     
                        // } 
                        // else{
                        //      this.commissionModel = true;
                        //      this.search.selectedCommissionModel = JSON.parse(response.data.reportDetails.salesCommissionPolicyModel);
                        // } 

                        //  if(response.data.reportDetails.salesCommissionPolicyFreqOfPayment == null || response.data.reportDetails.salesCommissionPolicyFreqOfPayment == -1){
                        //     this.commissionFreqOfPayment = false;     
                        // } 
                        // else{
                        //      this.commissionFreqOfPayment = true;
                        //      this.search.selectedFreqOfPayment = JSON.parse(response.data.reportDetails.salesCommissionPolicyFreqOfPayment);
                        // } 

                        //  if(response.data.reportDetails.pensionPolicy == null || response.data.reportDetails.pensionPolicy  == -1){
                        //     this.PermissionPolicyScheme = false;     
                        // } 
                        // else{
                        //      this.PermissionPolicyScheme = true;
                        //      this.search.selectedPermissionPolicyScheme = JSON.parse(response.data.reportDetails.pensionPolicy);
                        // } 
                        

                    }

                 });  

        },
        showReport(item){
             this.$axios({
                    method: 'get',
                    url: `${this.$baseURL}/Report/Generator?ReportId=${item.id}`,
                    headers: {'Content-Type': 'application/json' },
                }) .then((response)=>{
                    if(JSON.parse(response.data.status) == true)
                    {
                        this.reportGeneratorResult = response.data.generatedReport;
                        this.ReportHeader = item.reportHeader;
                        this.ReportDetails = item.reportDetails;
                        this.$refs.reportResult.refresh();
                    }
                    else{
                        this.reportGeneratorResult = []; 
                    }
                    
                });
        },
        generateReport(){
            this.$v.search.$touch();
            if (this.$v.search.$anyError) {
                return;
            }

            let data= {
                        SurveyYear:this.surveyYear,
                        IndustryType:this.search.selectedIndustryType,
                        BusinessSectors:this.search.selectedBusinessSectors == null?[]: this.search.selectedBusinessSectors.toString(),
                        BusinessSubSectors:this.search.selectedSubSectors == null?[]:this.search.selectedSubSectors.toString(),
                        LegalStructure:this.search.selctedLegalStructure,
                        JobFamily:this.search.selectedJobFamily??'',
                        Positions:this.search.selectedPositions??'',
                        CountryOfInc:this.search.selectedCountryOfIncorp,
                        CountryOfOp:this.search.selectedCountryOfOperation,
                        MedicalCoverageScope:this.search.selectedCoverageScope,
                        MedicalCoverageServiceProvider:this.search.selectedServiceProvider,
                        MedicalCoverageSchemeType:this.search.selectedSchemeType,
                        CoverageCategories:this.search.selectedCoverageCategories??'',
                        LifeInsuraceTypeOfCoverage:this.search.selctedTypeOfCoverage,
                        PhysicalDisTypeOfCoverage:this.search.selectedPhysicalDisTypeOfCoverage,
                        OverTimePolicy:this.search.selectedOvertimeTypeOfCoverage,
                        SalesCommissionPolicyBasis:this.search.selectedCommissionBasis,
                        SalesCommissionPolicySchemeModel:this.search.selectedCommissionSchemeModel,
                        SalesCommissionPolicyModel:this.search.selectedCommissionModel,
                        SalesCommissionPolicyFreqOfPayment:this.search.selectedFreqOfPayment,
                        PensionPolicy:this.search.selectedPermissionPolicyScheme,

                    }
            console.log(data);
           
            this.$axios({
                    method: 'post',
                    url: `${this.$baseURL}/Report/Generator`,
                    headers: {'Content-Type': 'application/json' },
                    data:data
                })
                .then((response)=>{
                    if(JSON.parse(response.data.status) == true)
                    {
                        console.log(response.data.generatedReport)
                        this.reportGeneratorResult = response.data.generatedReport;
                        this.$refs.reportResult.refresh();
                    }
                    else
                         this.reportGeneratorResult = null;
                });

        },
     },
     mounted(){
         this.loadReports();
     },
}
</script>

<style scoped>
        label{
        font-size: 13px;
    }
    table.scrolling .w2 {
	width: 20em;
	min-width: 20em;
	max-width: 30em;
}
table.scrolling tfoot tr th {
	width: 130em;
	min-width: 130em;
	max-width: 130em;
}
table.freezeFirstColumn thead tr,
table.freezeFirstColumn tbody tr {
	display: block;
	width: min-content;
}
table.freezeFirstColumn thead td:first-child,
table.freezeFirstColumn tbody td:first-child,
table.freezeFirstColumn thead th:first-child,
table.freezeFirstColumn tbody th:first-child {
	position: sticky;
	position: -webkit-sticky;
	left: 0;
}
* {
	font-family: sans-serif;
}
.box {
	clear: both;
	padding: 0;
	min-height: 300px;
	height: 60vh;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
}
</style>